/* Resume.css */

.reach {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    z-index: 1;
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  form {
    width: 80%;
    max-width: 400px; /* Adjust the maximum width based on your design */
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #858282;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  input,
  textarea {
    width: 95%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    background-color: #726f70;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #717880;
  }
  
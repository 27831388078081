/* Resume.css */

.resume {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
  }
  
  .resume h1 {
    color: #ffffff;
    padding-top: 50px;
    margin-bottom: 25px;
  }
  
  .resume img {
    width: 100%; /* Set the iframe width to 100% to make it responsive */
    height: 100%; /* Adjust the height based on your preference */
    max-width: 800px; /* Set a maximum width for larger screens */
    border: none;
  }
  
  .resume a{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media (max-width: 768px) {
    .resume img {
      height: 80vh; /* Adjust the height for smaller screens */
      width: 100%; /* Allow the iframe to take full width on smaller screens */
      max-height: none; /* Remove maximum height for smaller screens */
    }
  }
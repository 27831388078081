/* Contact.css */
.menu {
    /* Your menu styles here */
    /* Example styles: */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  button {
    /* Your button styles here */
    /* Example styles: */
    background: #9aa3a9;
    color: #fff;
    width: 100px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  
  ul {
    /* Your ul (list) styles here */
    /* Example styles: */
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    /* Your li (list item) styles here */
    /* Example styles: */
    margin: 10px 0;
    color: #fff;
  }

  a:hover{
    color: antiquewhite;
  }

  a{
    color:#b3b6b8;
  }
  
  @media (max-width: 768px) {
    .menu {
      flex-direction: column; /* Adjust the layout for smaller screens */
      padding: 10px;
    }
  
    button {
      width: 100px; /* Reduce button width for smaller screens */
      padding: 8px 16px; /* Adjust button padding */
      
    }

    ul{
      display: list-item;
    }
    /* Add any additional adjustments for smaller screens */
}
  
/* Projects.css */
.projects {
    text-align: center;
  }
  
  /* Style each video container */
  .video-container {
    display: flex;
    flex-direction: column; /* Stack videos and descriptions vertically on small screens */
    align-items: center;
    gap: 20px;
  }
  
  /* Media query for smaller screens (e.g., phones) */
@media (max-width: 768px) {
    /* Adjust the layout for smaller screens */
.video-container {
    flex-direction: column; /* Stack videos and descriptions vertically on small screens */
    align-items: center;
    gap: 10px;
}
  
/* Modify the font size for smaller screens */
.description p {
    font-size: 14px;
}
}
  
/* Home.css */

/* Styling for the .home class */
.home {
  display: flex;
  flex-direction: column; /* Set a column layout */
  padding: 20px;
  padding-top: 50px;
  /*background-image: url('back.jpg');
  background-size: cover; /* Ensure the image covers the entire container */
  background-position: center; /* Center the background image */
  
}

.header, .header h1{
  color: #fff;
  margin-right: auto;
  z-index: 1;
}

/* Styling for the image */
.image {
  flex: 1;
}

/* Styling for the img element within .image */
.image img {
  max-width: auto;
  width: auto; /* Make the image responsive */
  height: auto;
  border: 2px solid #333;
  border-radius: 5%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.case {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 60%;
  display: flex;
  margin-left: auto;
  padding-top: 20px;
  margin-bottom: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  background: rgba(128, 128, 128, 0.1); /* Grey background with 80% opacity */
  backdrop-filter: blur(5px); /* Apply a blur effect to the background */
}

.case-content {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
}

.case p, .case a {
  color: #fff;
  padding: 10px;
}

.case p{
  font-size: 20px;
}

.case a {
  text-decoration: none;
  background-color: #666b70;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 20px;
}

.case img {
  width: 400px;
  border-radius: 5%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  display: block;
}

.prevTitle {
  color: #fff;
  font-size: 25px;
}

@media (max-width: 768px) {
  .case {
    width: 80%;
    height: 40%;
  }

  .case p{
    width: 100%;
  }
  .case img {
    display: none;
}
}
.about-me {
    z-index: 1;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    position: relative;
  }
  
.image-holder{
    height: 100%; /* Maintain aspect ratio */
    width: 100%;
    margin-bottom: 20px; /* Add margin for spacing between image and text */
    display: flex;
    flex-direction: row;
}

.image-holder img{
    width: 70%;
    margin-left: auto;
}
.about-me-text{
    font-size: 24px;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

.about-me-text h2{
    text-align: center;
}
.about-me-text p {
    margin: auto;
    width: 70%;
    padding-bottom: 50px;
}

.about-me-text img{
    width: 50%;
    margin: auto;
}

@media (max-width: 768px) {
    .about-me{
        display: flex;
        flex-direction: column;
    }
    .image-holder img{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
  
    .about-me-text {
        margin: auto;
        font-size: 20px;
        width: 100%;
    }

    .about-me-text img{
        margin: auto;
        width: 100%;
    }
  }
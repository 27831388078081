/* Split the screen into two sections */
.split-screen {
    display: flex; /* Use flexbox for layout */
    z-index: 1;
    position: relative;
  }
  
  /* Left side of the screen */
  .left-section {
    flex: 1; /* Occupy 50% of the screen width */
    margin-top: 40px;
    overflow-y: auto; /* Enable vertical scrolling */
    min-width: 20%; 
    max-width: 50%;
  }
  
  /* Right side of the screen */
  .right-section {
    /* Add styles for the fixed view of the right section */
    position: fixed;
    top: 0;
    right: 0;
    width: 50%; /* Adjust the width as needed */
    height: 100vh;
    margin-top: 41px;
    color: #fff;
  }

  iframe{
    padding-top: 40px;
  }

  .image-list {
    list-style: none;
    padding: 0;
  }
  
  .image-list li {
    margin: 10px 0;
    color: rgb(255, 255, 255);
    font-size: 20px;
  }
  
  .image-list img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    /* Add more image styling as needed */
    width: 300px;
    height: 300px;
  }
  .gif-image{
    width: 400px;
    height: 100px;
    padding-top: 50px;
    align-items: center;
  }

  @media (max-width: 768px) {
    .split-screen {
      flex-direction: column; /* On smaller screens, switch to a column layout */
    }
    
    .left-section, .right-section {
      width: 100%; /* Occupy full width on smaller screens */
      position: static; /* Reset position for responsiveness */
      max-width: 100%; /* Adjust width to fit the screen */
      margin-top: 0; /* Reset top margin */
    }
    
    .right-section {
      position: fixed;
      padding-top:40px;
      width: 100%; /* Occupy full width */
      height: 50vh; /* Adjust height as needed */
      max-height: 50%; /* Example: Occupy 50% of the viewport height */
    }
    
    .left-section {
      margin-top: 50vh; /* Adjust the top margin to prevent overlap with the fixed right section */
    }
    
    .image-list img {
      margin-top:80px;
      width: 40%; /* Ensure images take the full width available */
      height: auto; /* Maintain aspect ratio */
      max-height: 50%; /* Adjust to fit smaller screens */
    } 
    
    .image-list img:hover{
      cursor: none;
    }
    .image-list li {
      margin: 5px 0; /* Adjust margin for smaller screens */
    }
    
    .gif-image {
      width: 100%; /* Adjust gif image width for smaller screens */
      height:100%;
    }

    .right-section iframe{
      width: 100%;
      height: 50vh;
    }
  }
  